footer{
    margin-top: 100px;
    /* background-color: #232323; */
    background-color: #1A1B1F;
    height: 18rem;
}

.contacts{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5.4em 0 1em 0;
}

.contacts a{
    margin: 0.5rem 1.4rem 0.5rem 1.4rem;
    width: 20px;
    height: 20px;
    opacity: 0.88;
}

.contacts img{
    width: 1.4em;
    height: 1.4em;
    transition: transform 0.3s ease;
    filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(282deg) brightness(104%) contrast(101%);
    color: blue;
}

.copyright p {
    font-size: 1em;
    font-weight: 300;
    letter-spacing: 1px;
}

.contacts img:hover{
    transform: translateY(-5px);
}

.copyright{
    margin-top: 0.8rem;
    text-align: center;
    color: rgb(255, 255, 255);
}

@media  screen and (max-width:400px) {
    .copyright p{
        font-size: 0.81em;
    }
} 