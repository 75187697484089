@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;700&display=swap');


*{
    font-family: 'Poppins', sans-serif;
}

.showcase{
    height: 100vh;
    min-height: -webkit-fill-available;
    z-index: 2;
    color: rgb(253, 253, 253);
    display: flex;
    flex-direction: column;
    color: white;
    background-color: #1A1B1F;
    font-family: 'Open Sans', sans-serif;
}

.prev {
    margin-top: 0.5em;
}

.text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 35%;
    left:0;
    width: 100%;
    z-index: 10;
    opacity: 0.9;
    color:rgb(255, 255, 255);
}

/* h3 a{
    text-decoration: none;
    color: white;
    letter-spacing: 1px;
} */

h3 a:hover{
    border-bottom: 1px solid white;
    padding-bottom: 3px;
}   

.slink{
    cursor: pointer;
}


.text h1{
    font-size: 4em;
    margin: 2rem 0 1.5rem 0;
    letter-spacing: 5px;
    font-weight: 800; /* Bold font */
    /* text-shadow: 1px 1px 4px rgba(255, 255, 255, 0.25); Slight shadow for depth */
}

.text h2{
    font-size: 1em;
    letter-spacing: 0.2em;
    font-weight: 700;
    color: rgb(255, 255, 255);
    text-shadow: 5px 5px 5px rgba(255, 255, 255, 0.093);
}

.text h3{
    font-size: 0.9em;
    font-weight: 350;
    letter-spacing: 2.5px;
}

.text  a{
    color: rgb(255, 255, 255);
    text-shadow: 1px 1px 4px rgba(255, 255, 255, 0.25);
    text-decoration: none;
}

.showcase img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

   /* .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #757575;
    mix-blend-mode: overlay;
}       */

.more-info{
    position: absolute;
    left: 50%;
    right: 50%;
    top:85%;
    margin: auto;
    text-align: center;
    z-index: 10;
    display: flex;
    justify-content: center;
}

.more-info img{
    width: 1em;
    height: auto;
    margin-top: 15px;
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
    filter: invert(100%) sepia(0%) saturate(7475%) hue-rotate(66deg) brightness(98%) contrast(107%);
}

@keyframes dropeffect{

    0%{
        opacity: 0;
        transform: translateY(-50px);
    }
    100%{
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(10px);
    }
    60% {
      transform: translateY(10px);
    }
  }

@media (max-width: 500px) {

    .text{
        top: 30%;
    }
    .text h1{
        font-size: 3.3em;
        letter-spacing:2px;
        margin-top: 2rem;
        margin-bottom: 4.5rem;
    }

    .text h2{
        font-size: 0.85em;
        letter-spacing: 0px;
    }

    .text h3{
        font-size: 0.8em;
        letter-spacing: 0.3px;
    } 
}

@media (max-width: 340px) {

    .text h1{
        font-size: 3.1em;
        letter-spacing:0.2px;
    }

    .text h2{
        font-size: 0.8em;
    }

    .text h3{
        font-size: 0.75em;
    } 
}