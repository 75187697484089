.company-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1px;
}

.company-title{
    text-align: center;
    margin-top: 3rem;
}

.job-desc {
    color: #4D4D4D;
}

.job-desc p{
    margin: 10px;
    text-align: center;
}

.job-desc li{
    margin: 8px;
}

.i{
    text-align: center;
    margin: 20px;
    color: #4D4D4D;
}

.company-title h1{
    margin-bottom: 8px;
    font-size: 35px;
    color: #4D4D4D;
}

.company-title p{
    margin: 5px;
}

.images{
    margin: 50px;
}

@media  screen and (max-width:600px) {


    .job-desc ul{
        margin: 2rem 2rem 0rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

}