@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;700&display=swap);

*{
  margin:0;
  padding:0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
}
nav{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 4.5rem;
    z-index: 400;
    font-weight: 200;
    width: 100%;
    transition: background 0.5s ease, color 0.5s ease;
}

.logo{
    width: 50%;
}

.logo a {
    color: white;
    text-decoration: none;
    letter-spacing: 2.5px;
    font-size: 1.2em;
    margin: 2rem
}

.links a{
    text-decoration: none;
    color: white;
    font-size: 0.9em;
    margin: 1.3rem;
    letter-spacing: 1px;
}

.links a:hover{
    border-bottom: 1px solid white;
    padding-bottom: 3px;
}   

.slink{
    cursor: pointer;
}


@media  screen and (max-width:450px) {

    nav{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 6rem;
    }

    .logo{
        text-align: center;
        margin-bottom: 0.2rem;
        width: 100%;
    } 

    .logo a {
        font-size: 0.9em;
    }
    
    .links a{
        font-size: 0.75em;
        margin: 2.5em;
    }
}
.company-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1px;
}

.company-title{
    text-align: center;
    margin-top: 3rem;
}

.job-desc {
    color: #4D4D4D;
}

.job-desc p{
    margin: 10px;
    text-align: center;
}

.job-desc li{
    margin: 8px;
}

.i{
    text-align: center;
    margin: 20px;
    color: #4D4D4D;
}

.company-title h1{
    margin-bottom: 8px;
    font-size: 35px;
    color: #4D4D4D;
}

.company-title p{
    margin: 5px;
}

.images{
    margin: 50px;
}

@media  screen and (max-width:600px) {


    .job-desc ul{
        margin: 2rem 2rem 0rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

}
/* .exp{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 5rem 0;
}

.experience-fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 5rem 0;
  
}

@keyframes fadeIn {
  to {
      opacity: 1;
  }
} */

.exp, .experience-fade-in, .experience-fade-out {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 5rem 0;
}

.experience-fade-in {
  opacity: 0;
  width: 100%;
  -webkit-animation: fadeIn 1s forwards;
          animation: fadeIn 1s forwards;
  transition: all 1s linear, visibility 0s;
  margin: 0;
}

.vibes{
  text-align: center;
  display: flex;
  color: orange;
  margin: -5rem auto 8rem auto;
  background-color: white;

  text-decoration: none;
  color: #55555577;
  font-size: 1em;
  border: 3px solid #EDEDED;
  padding: 15px 20px 15px 20px;
  border-radius: 0.3em;
  font-weight: 400;
  letter-spacing: 1px;
  cursor: pointer;
}

@-webkit-keyframes fadeOut {
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

.experience-fade-out {
  -webkit-animation: fadeOut 5s backwards;
          animation: fadeOut 5s backwards;

  width: 100%;
  transition: all 1s linear;
  margin: 0;
}

@-webkit-keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.title{
  text-align: center;
}

.title h1{
  margin: 0rem 0rem 1.5rem 0rem;
  font-size: 2.6em;
  /* color: #4D4D4D; */
  color: #4D4D4D;
  
}

.projs{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.more-projs{
  width: 100%;
  text-align:center;
  margin: 1.5rem 0 13rem 0;
  transition: -webkit-transform 0.45s ease;
  transition: transform 0.45s ease;
  transition: transform 0.45s ease, -webkit-transform 0.45s ease
}

.more-projs a{
  text-decoration: none;
  color: #55555577;
  font-size: 1em;
  border: 3px solid #EDEDED;
  padding: 15px 20px 15px 20px;
  border-radius: 0.3em;
  font-weight: 400;
  letter-spacing: 1px;
  transition: box-shadow 0.45s ease ;
}
/* #root{
  background-color: #ffdbc0;
} */


@media (max-width:900px) {

  .proj1{
    margin-right: 0rem;
  }
  
  .proj2{
    margin-left: 0rem;
  } 

  .projs{
    display: flex;
    justify-content: space-around;
  }
}

@media (max-width:600px) {

  .projs{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .proj1{
    margin-right: 0;
  }
  
  .proj2{
    margin-left: 0;
  }

  .title h1{
    font-size: 1.6em;
  }
}
*{
    font-family: 'Poppins', sans-serif;
}

.showcase{
    height: 100vh;
    min-height: -webkit-fill-available;
    z-index: 2;
    color: rgb(253, 253, 253);
    display: flex;
    flex-direction: column;
    color: white;
    background-color: #1A1B1F;
    font-family: 'Open Sans', sans-serif;
}

.prev {
    margin-top: 0.5em;
}

.text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 35%;
    left:0;
    width: 100%;
    z-index: 10;
    opacity: 0.9;
    color:rgb(255, 255, 255);
}

/* h3 a{
    text-decoration: none;
    color: white;
    letter-spacing: 1px;
} */

h3 a:hover{
    border-bottom: 1px solid white;
    padding-bottom: 3px;
}   

.slink{
    cursor: pointer;
}


.text h1{
    font-size: 4em;
    margin: 2rem 0 1.5rem 0;
    letter-spacing: 5px;
    font-weight: 800; /* Bold font */
    /* text-shadow: 1px 1px 4px rgba(255, 255, 255, 0.25); Slight shadow for depth */
}

.text h2{
    font-size: 1em;
    letter-spacing: 0.2em;
    font-weight: 700;
    color: rgb(255, 255, 255);
    text-shadow: 5px 5px 5px rgba(255, 255, 255, 0.093);
}

.text h3{
    font-size: 0.9em;
    font-weight: 350;
    letter-spacing: 2.5px;
}

.text  a{
    color: rgb(255, 255, 255);
    text-shadow: 1px 1px 4px rgba(255, 255, 255, 0.25);
    text-decoration: none;
}

.showcase img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

   /* .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #757575;
    mix-blend-mode: overlay;
}       */

.more-info{
    position: absolute;
    left: 50%;
    right: 50%;
    top:85%;
    margin: auto;
    text-align: center;
    z-index: 10;
    display: flex;
    justify-content: center;
}

.more-info img{
    width: 1em;
    height: auto;
    margin-top: 15px;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
    -webkit-filter: invert(100%) sepia(0%) saturate(7475%) hue-rotate(66deg) brightness(98%) contrast(107%);
            filter: invert(100%) sepia(0%) saturate(7475%) hue-rotate(66deg) brightness(98%) contrast(107%);
}

@-webkit-keyframes dropeffect{

    0%{
        opacity: 0;
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
    }
    100%{
        opacity: 1;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

@keyframes dropeffect{

    0%{
        opacity: 0;
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
    }
    100%{
        opacity: 1;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
    }
    60% {
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
    }
  }

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
    }
    60% {
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
    }
  }

@media (max-width: 500px) {

    .text{
        top: 30%;
    }
    .text h1{
        font-size: 3.3em;
        letter-spacing:2px;
        margin-top: 2rem;
        margin-bottom: 4.5rem;
    }

    .text h2{
        font-size: 0.85em;
        letter-spacing: 0px;
    }

    .text h3{
        font-size: 0.8em;
        letter-spacing: 0.3px;
    } 
}

@media (max-width: 340px) {

    .text h1{
        font-size: 3.1em;
        letter-spacing:0.2px;
    }

    .text h2{
        font-size: 0.8em;
    }

    .text h3{
        font-size: 0.75em;
    } 
}
.experience{
    display: flex;
    justify-content: space-around;
    width: 67%;
    align-items: center;
    border: 3px solid #EDEDED;
    padding: 3.4em 0.2em 3.4em 0.2em;
    border-radius: 1em;
    margin: 0rem 4rem 4rem 4rem;
    transition: box-shadow 0.45s ease, -webkit-transform 0.45s ease;
    transition: transform 0.45s ease, box-shadow 0.45s ease ;
    transition: transform 0.45s ease, box-shadow 0.45s ease, -webkit-transform 0.45s ease;
    text-decoration: none;
    color:black;
    background-color: white;
}

.experience:hover{
    box-shadow: 13px 14px 13px -12px rgba(97,97,97,1);  
    -webkit-transform: translateY(-10px);  
            transform: translateY(-10px);
}

.experience img{
    width: 13.3em;
    border-radius: 0.9em;
}

.info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 50%;
}

.e-title{
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 1.3em;
    opacity: 0.63;
}

.role{
    width: 95%;
    font-size: 1.7em;
    
    font-weight: 600;
}

.tags{
    display: flex;
    width: 100%;
}

.tags h5{
    margin: 0.7rem 1.3rem 0.7rem 0;
    font-size: 0.672em;
    background-color: #09193D;
    color: white;
    padding: 0.5em 1.2em 0.5em 1.2em;
    border-radius: 2em;
    font-weight: 400;
}

.description{
    font-weight: 300;
    font-size: 0.95em;
}

.e-link{
    text-decoration: none;
    color: grey;
    opacity: 0.9;
    margin-top: 0.9rem;
    font-size: 1.08em;
    font-weight: 600;
    transition: color 0.5s ease, -webkit-transform 0.5s ease;
    transition: color 0.5s ease, transform 0.5s ease;
    transition: color 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
}

.e-link:hover{
    -webkit-transform: translateX(0.6em);
            transform: translateX(0.6em);
}

@media (min-width: 1600px){

    .experience {
        width: 55%;
    }
}

@media (max-width: 1000px){

    .experience img{
        width: 10.3em;
    }

    .e-title{
        font-size: 0.77em;
    }

    .role{
        width: 100%;
        font-size: 1em;
    }

    .tags h5{
        margin: 0.7rem 0.7rem 0.7rem 0;
        font-size: 0.5em;
    }

    .description{
        font-size: 0.75em;
    }

    .e-link{
        font-size: 0.85em;
    }
}

@media (max-width:900px) {

    .experience{
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 650px){

    .experience{
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 66%;
        padding: 1.5em 0em 1.5em 0em;
        height: 40rem;
    }

    .info{
        width:80%;
        display: flex;
        justify-content: center;
    }

    .experience img{
        width: 11.3em;
    }

    .e-title{
        width: 100%;
        text-align: center;
        font-size: 0.9em;
        margin-bottom: 1rem;
    }

    .role{
        width: 100%;
        font-size: 1.25em;
    }

    .tags{
        margin: 1rem 0 1rem 0;
    }

    .tags h5{
        font-size: 0.7em;
    }

    .description{
        font-size: 0.85em;
        margin: 0.3rem 0 0.5rem 0;
    }

    .e-link{
        width: 100%;
        text-align: center;
        font-size: 0.95em;
    }
}

 @media (max-width: 620px){

    .experience{
        margin: auto;
        width: 80%;
        margin-bottom: 2rem;
    }

    .tags{
        margin: 0.3rem 0 0.3rem 0;
    }

    .tags h5{
        font-size: 0.55em;
    }

    .experience img{
        width: 14em;
    }

    .description{
        font-size: 0.78em;
        text-align: left;
    }

    .e-title{
        font-size: 0.85em;
        margin-bottom: 0.5rem;
        text-align: left;
    }

    .role{
        font-size: 1.1em;
        text-align: left;
    }

    .e-link{
        text-align: left;
    }
} 
.project{
    display: flex;
    justify-content: space-around;
    width: 67%;
    align-items: center;
    border: 3px solid #EDEDED;
    padding: 3.4em 0.2em 3.4em 0.2em;
    border-radius: 1em;
    margin: 0rem 4rem 4rem 4rem;
    transition: box-shadow 0.45s ease, -webkit-transform 0.45s ease;
    transition: transform 0.45s ease, box-shadow 0.45s ease ;
    transition: transform 0.45s ease, box-shadow 0.45s ease, -webkit-transform 0.45s ease;
    text-decoration: none;
    color:black;
}

.project:hover{
    box-shadow: 13px 14px 13px -12px rgba(97,97,97,1);  
    -webkit-transform: translateY(-10px);  
            transform: translateY(-10px);
}

.project img{
    width: 17.3em;
    border-radius: 0.9em;
}

.image{
    display: flex;
    align-items: center;
}

.info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 50%;
}

.e-title{
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 1.3em;
    color: rgba(90, 90, 90, 0.582);
    opacity: 0.7;
}

.role{
    width: 95%;
    font-size: 1.7em;
    opacity: 0.7;
    font-weight: 600;
}

.tags{
    display: flex;
    width: 100%;
}

.tags h5{
    margin: 0.7rem 1.3rem 0.7rem 0;
    font-size: 0.672em;
    background-color: #09193D;
    color: white;
    padding: 0.5em 1.2em 0.5em 1.2em;
    border-radius: 2em;
    font-weight: 400;
}

.description{
    font-weight: 300;
    font-size: 0.95em;
}

.e-link{
    text-decoration: none;
    color: grey;
    opacity: 0.9;
    margin-top: 0.9rem;
    font-size: 1.08em;
    font-weight: 600;
    transition: color 0.5s ease, -webkit-transform 0.5s ease;
    transition: color 0.5s ease, transform 0.5s ease;
    transition: color 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
}

.e-link:hover{
    -webkit-transform: translateX(0.6em);
            transform: translateX(0.6em);
}

@media (min-width: 1600px){

    .project {
        width: 55%;
    }
}

@media (max-width: 1000px){

    .project img{
        width: 10.3em;
    }

    .e-title{
        font-size: 0.77em;
    }

    .role{
        width: 100%;
        font-size: 1em;
    }

    .tags h5{
        margin: 0.7rem 0.7rem 0.7rem 0;
        font-size: 0.5em;
    }

    .description{
        font-size: 0.75em;
    }

    .e-link{
        font-size: 0.85em;
    }
}

@media (max-width:900px) {

    .project{
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 650px){

    .project{
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 66%;
        padding: 1.5em 0em 1.5em 0em;
        height: 40rem;
    }

    .info{
        width:80%;
        display: flex;
        justify-content: center;
    }

    .project img{
        width: 11.3em;
    }

    .e-title{
        width: 100%;
        text-align: center;
        font-size: 0.9em;
        margin-bottom: 1rem;
    }

    .role{
        width: 100%;
        font-size: 1.25em;
    }

    .tags{
        margin: 1rem 0 1rem 0;
    }

    .tags h5{
        font-size: 0.7em;
    }

    .description{
        font-size: 0.85em;
        margin: 0.3rem 0 0.5rem 0;
    }

    .e-link{
        width: 100%;
        text-align: center;
        font-size: 0.95em;
    }
}

 @media (max-width: 620px){

    .project{
        margin: auto;
        width: 80%;
        margin-bottom: 2rem;
    }

    .tags{
        margin: 0.3rem 0 0.3rem 0;
    }

    .tags h5{
        font-size: 0.55em;
    }

    .project img{
        width: 14em;
    }

    .description{
        font-size: 0.78em;
        text-align: left;
    }

    .e-title{
        font-size: 0.85em;
        margin-bottom: 0.5rem;
        text-align: left;
    }

    .role{
        font-size: 1.1em;
        text-align: left;
    }

    .e-link{
        text-align: left;
    }
} 
.about-title{
    text-align: center;
    margin-top: 3rem;
}

.about-title h1{
    margin-bottom: 30px;
    font-size: 35px;
    color: #4D4D4D;
}

.alink {
    color: rgb(122, 157, 239);
    text-decoration: none;
}

.about-info{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-image img{
    width: 420px;
    height:550px;
    margin-right: 120px;
    border-top-right-radius: 30%;
    border-bottom-left-radius: 30%;
    border-top-left-radius: 5%;
    border-bottom-right-radius: 5%;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.081);
}

.jot-note-wrapper{
    display: flex;
    justify-content: center;
}

.jot-note-wrapper .block{
    margin: 15px 50px 50px 50px;
    padding: 30px 60px 60px 60px;
    color: black;
    border-top-left-radius: 20%;
    border-bottom-right-radius: 20%;
    border: 2px solid rgba(70, 70, 70, 0.472);

    border-top-right-radius: 5%;
    border-bottom-left-radius: 5%;

}

.block h3{
    font-weight: 400;
    font-size: 25px;
    margin-bottom: 20px;
}

.block ul{
    margin-top: 10px;
}

.block li{
    font-weight: 300;
    font-size: 15px;
    margin: 5px;
}

.jot-note-wrapper ul{
    list-style-type: none;
}

.a-title {
    display: flex;
    width: 100%;
}

.about-info p{
    color: #4D4D4D;
    margin: 10px 0 10px 0;
    letter-spacing: 0.3px;
}

.a-title h1{
    font-weight: 700;
    color: rgb(35, 35, 35);
    padding-bottom: 5px;
    border-bottom: 2px solid rgb(117, 186, 255);
    margin-bottom: 20px;
}

.song-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.song{
    margin: 20px;
}

.songs{
    display: flex;
    justify-content: center;
    width: 80%;
}

.about-header{
    margin-top: 120px;
    display: flex;
    justify-content: center;
}

.about-break{
    background-color: #4D4D4D;
    width: 10px;
    margin: 100px;
}

.final-text p{
    letter-spacing: 0.3px;
    color: #4D4D4D;
    margin: 5px;
}

.final-text{
    display: flex;
    justify-content: center;
}

@media  screen and (max-width:900px) {
    .about-header{
        flex-direction: column;
        align-items: center;
    }
    .about-info p{
        width: 300px;
        margin: 20px;
    }

    .about-image img{
        width: 350px;
        height: 450px;
        margin-right: 0px;
    }

    .a-title{
        width: 300px;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top:40px;
    }

    .songs, .jot-note-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .song{
        margin: 0;
    }

    .final-text{
        text-align: center;
    }
} 

.blocker{
    margin: 100px;
    background-color: rgb(255, 246, 246);
    padding: 20px;
    color: rgb(181, 58, 58);
}
footer{
    margin-top: 100px;
    /* background-color: #232323; */
    background-color: #1A1B1F;
    height: 18rem;
}

.contacts{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5.4em 0 1em 0;
}

.contacts a{
    margin: 0.5rem 1.4rem 0.5rem 1.4rem;
    width: 20px;
    height: 20px;
    opacity: 0.88;
}

.contacts img{
    width: 1.4em;
    height: 1.4em;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    -webkit-filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(282deg) brightness(104%) contrast(101%);
            filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(282deg) brightness(104%) contrast(101%);
    color: blue;
}

.copyright p {
    font-size: 1em;
    font-weight: 300;
    letter-spacing: 1px;
}

.contacts img:hover{
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
}

.copyright{
    margin-top: 0.8rem;
    text-align: center;
    color: rgb(255, 255, 255);
}

@media  screen and (max-width:400px) {
    .copyright p{
        font-size: 0.81em;
    }
} 
