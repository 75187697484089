/* .exp{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 5rem 0;
}

.experience-fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 5rem 0;
  
}

@keyframes fadeIn {
  to {
      opacity: 1;
  }
} */

.exp, .experience-fade-in, .experience-fade-out {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 5rem 0;
}

.experience-fade-in {
  opacity: 0;
  width: 100%;
  animation: fadeIn 1s forwards;
  transition: all 1s linear, visibility 0s;
  margin: 0;
}

.vibes{
  text-align: center;
  display: flex;
  color: orange;
  margin: -5rem auto 8rem auto;
  background-color: white;

  text-decoration: none;
  color: #55555577;
  font-size: 1em;
  border: 3px solid #EDEDED;
  padding: 15px 20px 15px 20px;
  border-radius: 0.3em;
  font-weight: 400;
  letter-spacing: 1px;
  cursor: pointer;
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

.experience-fade-out {
  animation: fadeOut 5s backwards;

  width: 100%;
  transition: all 1s linear;
  margin: 0;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.title{
  text-align: center;
}

.title h1{
  margin: 0rem 0rem 1.5rem 0rem;
  font-size: 2.6em;
  /* color: #4D4D4D; */
  color: #4D4D4D;
  
}

.projs{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.more-projs{
  width: 100%;
  text-align:center;
  margin: 1.5rem 0 13rem 0;
  transition: transform 0.45s ease,
}

.more-projs a{
  text-decoration: none;
  color: #55555577;
  font-size: 1em;
  border: 3px solid #EDEDED;
  padding: 15px 20px 15px 20px;
  border-radius: 0.3em;
  font-weight: 400;
  letter-spacing: 1px;
  transition: box-shadow 0.45s ease ;
}
/* #root{
  background-color: #ffdbc0;
} */


@media (max-width:900px) {

  .proj1{
    margin-right: 0rem;
  }
  
  .proj2{
    margin-left: 0rem;
  } 

  .projs{
    display: flex;
    justify-content: space-around;
  }
}

@media (max-width:600px) {

  .projs{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .proj1{
    margin-right: 0;
  }
  
  .proj2{
    margin-left: 0;
  }

  .title h1{
    font-size: 1.6em;
  }
}