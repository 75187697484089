.about-title{
    text-align: center;
    margin-top: 3rem;
}

.about-title h1{
    margin-bottom: 30px;
    font-size: 35px;
    color: #4D4D4D;
}

.alink {
    color: rgb(122, 157, 239);
    text-decoration: none;
}

.about-info{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-image img{
    width: 420px;
    height:550px;
    margin-right: 120px;
    border-top-right-radius: 30%;
    border-bottom-left-radius: 30%;
    border-top-left-radius: 5%;
    border-bottom-right-radius: 5%;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.081);
}

.jot-note-wrapper{
    display: flex;
    justify-content: center;
}

.jot-note-wrapper .block{
    margin: 15px 50px 50px 50px;
    padding: 30px 60px 60px 60px;
    color: black;
    border-top-left-radius: 20%;
    border-bottom-right-radius: 20%;
    border: 2px solid rgba(70, 70, 70, 0.472);

    border-top-right-radius: 5%;
    border-bottom-left-radius: 5%;

}

.block h3{
    font-weight: 400;
    font-size: 25px;
    margin-bottom: 20px;
}

.block ul{
    margin-top: 10px;
}

.block li{
    font-weight: 300;
    font-size: 15px;
    margin: 5px;
}

.jot-note-wrapper ul{
    list-style-type: none;
}

.a-title {
    display: flex;
    width: 100%;
}

.about-info p{
    color: #4D4D4D;
    margin: 10px 0 10px 0;
    letter-spacing: 0.3px;
}

.a-title h1{
    font-weight: 700;
    color: rgb(35, 35, 35);
    padding-bottom: 5px;
    border-bottom: 2px solid rgb(117, 186, 255);
    margin-bottom: 20px;
}

.song-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.song{
    margin: 20px;
}

.songs{
    display: flex;
    justify-content: center;
    width: 80%;
}

.about-header{
    margin-top: 120px;
    display: flex;
    justify-content: center;
}

.about-break{
    background-color: #4D4D4D;
    width: 10px;
    margin: 100px;
}

.final-text p{
    letter-spacing: 0.3px;
    color: #4D4D4D;
    margin: 5px;
}

.final-text{
    display: flex;
    justify-content: center;
}

@media  screen and (max-width:900px) {
    .about-header{
        flex-direction: column;
        align-items: center;
    }
    .about-info p{
        width: 300px;
        margin: 20px;
    }

    .about-image img{
        width: 350px;
        height: 450px;
        margin-right: 0px;
    }

    .a-title{
        width: 300px;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top:40px;
    }

    .songs, .jot-note-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .song{
        margin: 0;
    }

    .final-text{
        text-align: center;
    }
} 
